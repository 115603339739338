import { Box, Typography } from "@mui/material";
import { Font, Palette } from "./palette";
import behance from "./behance.svg";
export const Footer = () => {
  const handleClickBehance = () => {
    window.open("https://www.behance.com/vivianladino", "_blank");
  };
  return (
    <Box
      sx={{
        backgroundColor: Palette.PINK,
        height: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "2rem 0",
      }}
    >
      <Typography
        sx={{
          fontFamily: Font.RAILWAY,
          fontSize: {
            xs: "1.2rem",
            md: "1.5rem",
          },
        }}
      >
        sariafranco2004@gmail.com
      </Typography>
      <Box
        sx={{
          cursor: "pointer",
          marginTop: {
            xs: "20px",
            md: "10px",
          },
        }}
      >
        <img
          className="scale-on-hover-big"
          src={behance}
          alt="behance"
          width="40px"
          onClick={handleClickBehance}
        />
      </Box>
    </Box>
  );
};
