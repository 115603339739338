export const Palette = {
  BLACK: "rgb(0, 0, 0)",
  RED: "rgb(228,96,90)",
  PINK: "rgb(243,187,185)",
  LIGHT_PINK: "rgb(252,238,237)",
  WHITE: "rgb(255, 255, 255)",
};

export const Font = {
  CEHUA: "Cehua",
  RAILWAY: "Raleway",
  RAILWAY_ITALIC: "Raleway-Italic",
};
