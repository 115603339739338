import { Box, Button, Pagination, Typography } from "@mui/material";
import { Font, Palette } from "./palette";
import React, { useEffect } from "react";

export const PortfolioView = ({ portfolio }) => {
  const [portfolioPage, setPortfolioPage] = React.useState(0);
  const handlePaginationChange = (event, value) => {
    setPortfolioPage(value - 1);
  };
  const handleClickViewPdf = (event) => {
    window.open(portfolio.pdfUrl, "_blank");
  };
  useEffect(() => {
    setPortfolioPage(0);
  }, [portfolio]);
  return (
    <Box
      sx={{
        backgroundColor: Palette.LIGHT_PINK,
        padding: {
          xs: "50px 20px",
          md: "50px 10%",
          lg: "50px 12%",
        },

        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        style={{
          fontFamily: Font.CEHUA,
          fontSize: "2rem",
          textAlign: "center",
        }}
      >
        {portfolio.title}
      </Typography>
      <Typography
        sx={{
          fontFamily: Font.RAILWAY,
          fontSize: "1rem",
          fontWeight: "bold",
          marginTop: "10px",
          textAlign: "center",
        }}
      >
        {portfolio.subtitle}
      </Typography>
      <Typography
        sx={{
          fontFamily: Font.RAILWAY,
          fontSize: "0.9rem",
          fontWeight: "light",
          marginTop: "15px",
          marginBottom: "30px",
          textAlign: {
            xs: "center",
          },
        }}
      >
        {portfolio.descriptions[portfolioPage]}
      </Typography>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {portfolio.numPages > 1 && (
          <Pagination
            page={portfolioPage + 1}
            count={portfolio.numPages}
            onChange={handlePaginationChange}
          />
        )}
        <Button
          variant="outlined"
          sx={{
            position: {
              xs: "block",
              md: "absolute",
            },
            right: "0",
            fontFamily: Font.RAILWAY,
          }}
          onClick={handleClickViewPdf}
        >
          View PDF
        </Button>
      </Box>
      <br></br>
      <Box
        className="scale-on-hover-small"
        sx={{ boxShadow: 5 }}
        style={{
          position: "relative",
        }}
      >
        <img
          src={portfolio.pngUrls.large[portfolioPage]}
          alt={portfolio.title}
          style={{
            width: "100%",
            cursor: "pointer",
          }}
          onClick={handleClickViewPdf}
        />
      </Box>
    </Box>
  );
};
