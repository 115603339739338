export const Portfolio = [
  {
    title: "MOJO Milk",
    subtitle: "Packaging, Illustrator",
    pdfUrl:
      "https://storage.googleapis.com/sariah-portfolio-files/pdfs/mojo_milk.pdf",
    pngUrls: {
      large: [
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/large/mojo_milk-1.png",
      ],
      small: [
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/small/mojo_milk-1.png",
      ],
    },
    descriptions: [
      "Introducing Mojo Milk, a captivating packaging design that showcases a new logo and refreshing can designs. Combining the timeless allure of black and white with a vibrant splash of color, each can represents the unique flavor it holds. This playful yet elegant design captures the essence of Mojo Milk, enticing consumers with its irresistitable charm.",
    ],
    numPages: 1,
  },
  {
    title: "Buns n' Burgers",
    subtitle: "Brand Design, Packaging, Illustrator",
    descriptions: [
      "In a remarkable breakthrough in brand design, Buns n' Burgers emerges with a captivating identity that revolutionizes the fast-food industry. The vibrant combination of blue and pink establishes a bold and energetic visual language, attracting attention and creating an instant connection with the target audience. Infused with ASL (American Sign Language) graphic elements, the logo embodies inclusivity and celebrates diversity, making Buns n' Burgers a trailblazer in promoting accessibility and equality in the culinary world.",
      "The packaging design takes the Bn'B experience to new heights. Each box and wrapper creates an immersive visual feast that stimulates the senses. With meticulous attention to detail, the packaging seamlessly integrates ASL graphic elements, reinforcing the brand's commitment to inclusivity. This approach not only sets Buns n' Burgers apart from its competitors but also establishes an emotional connection with customers. Buns n' Burgers brand design and packaging truly encapsulate a revolutionary fusion of vibrancy and inclusivity, ensuring an unforgettable dining experience for all.",
    ],
    pdfUrl:
      "https://storage.googleapis.com/sariah-portfolio-files/pdfs/buns_n_burgers.pdf",
    pngUrls: {
      large: [
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/large/buns_n_burgers-1.png",
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/large/buns_n_burgers-2.png",
      ],
      small: [
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/small/buns_n_burgers-1.png",
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/small/buns_n_burgers-2.png",
      ],
    },
    numPages: 2,
  },
  {
    title: "The Buzz 99.9",
    subtitle: "Re-brand Design, Illustrator",
    descriptions: [
      "The Buzz rock radio station undergoes a thrilling brand redesign, starting with a unique logo that defies expectations. The soft and intricate illustration-based design contrasts the station's rock genre, capturing attention with its unexpected approach. The vibrant palette of yellow, black, and white further amplifies the logo's impact, creating a visually striking representation of The Buzz's dynamic and energetic personality. This one-of-a-kind logo stands as a testament to the station's ability to embrace artistic diversity and push boundaries in the world of rock radio.",
      "The redesign extends beyond the logo, permeating into apparel and miscellaneous items. From t-shirts to stickers. The Buzz's new aesthetic is reflected in every detail. The apparel collection showcases the same captivating color palette, ensuring that fans can proudly wear their support for the station. With carefully crafted designs that exude a modern and edgy vibe. The Buzz's merchandise becomes a statement piece, enticing both loyal listeners and newcomers to join the rock revolution. The brand's dedication to delivering a cohesive and visually captivating experience is evident in every aspect of their apparel and miscellaneous items.",
    ],
    pdfUrl:
      "https://storage.googleapis.com/sariah-portfolio-files/pdfs/the_buzz_99.9.pdf",
    pngUrls: {
      large: [
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/large/the_buzz_99.9-1.png",
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/large/the_buzz_99.9-2.png",
      ],
      small: [
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/small/the_buzz_99.9-1.png",
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/small/the_buzz_99.9-2.png",
      ],
    },
    numPages: 2,
  },
  {
    title: "ARIA FREE Headphones",
    subtitle: "Packaging, Illustrator",
    descriptions: [
      "Immerse yourself in the world of music with Aria Free headphones packaging. The sleek and sophisticated design captivates the senses, combining the timeless allure of black with the delicate touch lilac. This harmonious color scheme sets the stage for an exceptional audio experience, reflecting the premium quality of the headphones. With a focus on impeccable design, the Aria Free packaging effortlessly merges style and functionality, presenting a modern and captivating packaging solution.",
    ],
    pdfUrl:
      "https://storage.googleapis.com/sariah-portfolio-files/pdfs/aria_free_headphones.pdf",
    pngUrls: {
      large: [
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/large/aria_free_headphones-1.png",
      ],
      small: [
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/small/aria_free_headphones-1.png",
      ],
    },
    numPages: 1,
  },
  {
    title: "Saie Packaging Collection",
    subtitle: "Packaging, Illustrator",
    descriptions: [
      "This vibrant and eye-catching design showcases a captivating gradient of enchanting pinks and blue, evoking a sense of playfulness and individuality. The simple, yet bold black typography perfectly complements the colorful backdrop. Saie's packaging collection exudes a captivating charm, inviting customers to explore the brand's colorful world of beauty.",
    ],
    pdfUrl:
      "https://storage.googleapis.com/sariah-portfolio-files/pdfs/saie_packaging_collection.pdf",
    pngUrls: {
      large: [
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/large/saie_packaging_collection-1.png",
      ],
      small: [
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/small/saie_packaging_collection-1.png",
      ],
    },
    numPages: 1,
  },
  {
    title: "The Brick Flyer",
    subtitle: "Page layout, InDesign",
    descriptions: [
      'Showcasing a dynamic collection of flyer designs created for "The Brick". Each flyer is a harmonious blend of captivating visuals and strategic layout, tailored to capture the essence of The Brick\'s identity. From bold typography to vibrant imagery, which not only inform but also engage and resonate with the audience.',
    ],
    pdfUrl:
      "https://storage.googleapis.com/sariah-portfolio-files/pdfs/the_brick_flyer.pdf",
    pngUrls: {
      large: [
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/large/the_brick_flyer-1.png",
      ],
      small: [
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/small/the_brick_flyer-1.png",
      ],
    },
    numPages: 1,
  },
  {
    title: "The Mexican Cantina Menu",
    subtitle: "Page layout, InDesign",
    descriptions: [
      "This carefully crafted menu is a culinary journey, artfully showcasing the rich tapestry of Mexican cuisine. The fusion of authentic imagery, playful typography, and intuitive layout not only guides diners through the menu but also transports them to the heart of Mexico.",
    ],
    pdfUrl:
      "https://storage.googleapis.com/sariah-portfolio-files/pdfs/the_mexican_cantina_menu.pdf",
    pngUrls: {
      large: [
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/large/the_mexican_cantina_menu-1.png",
      ],
      small: [
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/small/the_mexican_cantina_menu-1.png",
      ],
    },
    numPages: 1,
  },
  {
    title: "Arctic Blast Gum Packaging",
    subtitle: "Package, Illustrator",
    descriptions: [
      "This box boasts a frosty allure, enhanced by a UV coating. The tactile thrill of embossed patterns adds a touch of elegance, inviting users to feel the chill as they unwrap an Arctic adventure with every piece. This packaging design harmoniously blends visual and tactile elements, embodying the essence of cool refreshment.",
    ],
    numPages: 1,
    pdfUrl:
      "https://storage.googleapis.com/sariah-portfolio-files/pdfs/arctic_blask_gum_packaging.pdf",
    pngUrls: {
      large: [
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/large/arctic_blask_gum_packaging-1.png",
      ],
      small: [
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/small/arctic_blask_gum_packaging-1.png",
      ],
    },
  },
  {
    title: "Mercedes-Benz Social Media Campaign",
    subtitle: "Marketing, Photoshop",
    descriptions: [
      "Crafted meticulously from over 15 distinct images, each post is a testament to the art of automotive design. Seamlessly combined in Photoshop, these visuals showcase the evolution of elegance, power, and sophistication that defines Mercedes-Benz. From the sleek contours to the precision details, each image weaves a story of craftsmanship.",
    ],
    numPages: 1,
    pdfUrl:
      "https://storage.googleapis.com/sariah-portfolio-files/pdfs/mercedes_benz_social_media_campaign.pdf",
    pngUrls: {
      large: [
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/large/mercedes_benz_social_media_campaign-1.png",
      ],
      small: [
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/small/mercedes_benz_social_media_campaign-1.png",
      ],
    },
  },
  {
    title: "Pulpas Ameccus Packaging",
    subtitle: "Packaging, Illustrator",
    descriptions: [
      "Playful colors dance harmoniously, celebrating the pure essence of nature. From orchard to packaging, simplicity meets vividness in a design that is as fresh as the fruits it holds.",
    ],
    numPages: 1,
    pdfUrl:
      "https://storage.googleapis.com/sariah-portfolio-files/pdfs/pulpas_ameccus_packaging.pdf",
    pngUrls: {
      large: [
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/large/pulpas_ameccus_packaging-1.png",
      ],
      small: [
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/small/pulpas_ameccus_packaging-1.png",
      ],
    },
  },
  {
    title: "Pennzoil Mailers",
    subtitle: "Page layout, InDesign",
    descriptions: [
      "Through the use of variable data, these mailers are tailored to individual recipients, offering custom product suggestions and exclusive deals. Experience a new level of personalized automotive maintenance that brings convenience and relevance right to your doorstop.",
    ],
    numPages: 1,
    pdfUrl:
      "https://storage.googleapis.com/sariah-portfolio-files/pdfs/pennzoil_mailers.pdf",
    pngUrls: {
      large: [
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/large/pennzoil_mailers-1.png",
      ],
      small: [
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/small/pennzoil_mailers-1.png",
      ],
    },
  },
  {
    title: "Toblerone Eastern Edition",
    subtitle: "Packaging, Illustrator",
    descriptions: [
      "Wrapped in a captivating yellow to light pink gradient, the packaging embodies the essence of spring. The iconic triangular box holds the promise of delightful moments, combining Swiss chocolate tradition with the joy of Easter. It's not just a treat; it is a visual and flavorful celebration that captures the spirit of renewal and happiness.",
    ],
    numPages: 1,
    pdfUrl:
      "https://storage.googleapis.com/sariah-portfolio-files/pdfs/toblerone_eastern_edition.pdf",
    pngUrls: {
      large: [
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/large/toblerone_eastern_edition-1.png",
      ],
      small: [
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/small/toblerone_eastern_edition-1.png",
      ],
    },
  },
  {
    title: "Home Depot Brochure",
    subtitle: "Page layout, InDesign",
    descriptions: [
      "This brochure is a comprehensive guide to transforming spaces. Vibrant visuals showcase a wide array of products, while concise descriptions simplify decision-making. The intuitive layout and user-friendly format provide guidance through the reading process.",
    ],
    numPages: 1,
    pdfUrl:
      "https://storage.googleapis.com/sariah-portfolio-files/pdfs/home_depot_brochure.pdf",
    pngUrls: {
      large: [
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/large/home_depot_brochure-1.png",
      ],
      small: [
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/small/home_depot_brochure-1.png",
      ],
    },
  },
  {
    title: "Casablanca Magazine",
    subtitle: "Page layout, InDesign",
    descriptions: [
      "Immerse yourself in a symphony of page layouts that seamlessly blend from and function. Each spread is a canvas of sophistication, balancing captivating product imagery with insightful content.",
    ],
    numPages: 1,
    pdfUrl:
      "https://storage.googleapis.com/sariah-portfolio-files/pdfs/casablanca_magazine.pdf",
    pngUrls: {
      large: [
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/large/casablanca_magazine-1.png",
      ],
      small: [
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/small/casablanca_magazine-1.png",
      ],
    },
  },
  {
    title: "Stranded Movie Poster",
    subtitle: "Print, Photoshop",
    descriptions: [
      "Crafted from over 10 meticulously curated images using Photoshop, this poster captures the journey of survival against all odds. Each image weaves into a visual narrative, telling a tale of strength, unity, and determination.",
    ],
    numPages: 1,
    pdfUrl:
      "https://storage.googleapis.com/sariah-portfolio-files/pdfs/stranded_movie_poster.pdf",
    pngUrls: {
      large: [
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/large/stranded_movie_poster-1.png",
      ],
      small: [
        "https://storage.googleapis.com/sariah-portfolio-files/pngs/small/stranded_movie_poster-1.png",
      ],
    },
  },
];
