import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import React from "react";
import { Font, Palette } from "./palette";
import { Portfolio } from "./portfolio";

import { PortfolioView } from "./PortfolioView";

export const ListPortal = () => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  return (
    <Box
      style={{
        display: "flex",
        backgroundColor: Palette.PINK,
      }}
    >
      <Box
        sx={{
          minWidth: "360px",
          height: "100vh",
          bgcolor: Palette.PINK,
        }}
        overflow="scroll"
      >
        <List component="nav" aria-label="main mailbox folders">
          {Portfolio.map((portfolio, index) => (
            <ListItemButton
              key={index}
              selected={selectedIndex === index}
              onClick={(event) => handleListItemClick(event, index)}
            >
              <ListItemIcon>
                <BookmarkIcon />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  style: {
                    fontFamily: Font.CEHUA,
                  },
                }}
                secondaryTypographyProps={{
                  style: {
                    fontFamily: Font.RAILWAY,
                    fontWeight: "bold",
                  },
                }}
                primary={portfolio.title}
                secondary={portfolio.subtitle}
              />
            </ListItemButton>
          ))}
        </List>
      </Box>
      <PortfolioView portfolio={Portfolio[selectedIndex]} />
    </Box>
  );
};
