import { Box, Tab, Tabs, ThemeProvider, createTheme } from "@mui/material";
import "./App.css";
import { Landing } from "./Landing";
import { ListPortal } from "./ListPortal";
import { GridPortal } from "./GridPortal";
import React from "react";
import { Font, Palette } from "./palette";
import { Footer } from "./Footer";

const theme = createTheme({
  palette: {
    primary: {
      main: Palette.RED,
    },
    secondary: {
      main: Palette.RED,
    },
  },
});

function App() {
  const [value, setValue] = React.useState("grid");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Landing />
        <Box
          sx={{
            display: {
              xs: "none",
              lg: "flex",
            },
            justifyContent: "center",
            backgroundColor: Palette.LIGHT_PINK,
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Tab
              value="grid"
              label="Grid View"
              style={{
                fontFamily: Font.RAILWAY,
              }}
            />
            <Tab
              value="list"
              label="List View"
              style={{
                fontFamily: Font.RAILWAY,
              }}
            />
          </Tabs>
        </Box>
        {value === "list" && <ListPortal />}
        {value === "grid" && <GridPortal />}
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;
