import { Box, Typography } from "@mui/material";
import { Font, Palette } from "./palette";
import React from "react";
import portfolio from "./portfolio.png";
import name from "./name.svg";

export const Landing = () => {
  return (
    <Box
      sx={{
        height: {
          xs: "70vh",
          md: "90vh",
        },
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box
        style={{
          zIndex: "-1",
          position: "absolute",
          width: "100%",
          height: "100%",
          backgroundColor: Palette.PINK,
          opacity: 0.75,
        }}
      ></Box>
      <Box
        sx={{
          marginBottom: "10px",
          width: {
            xs: "calc(100% - 40px)",
            md: "50%",
          },
        }}
      >
        <img src={name} alt="name" width="100%" />
      </Box>
      <Box
        sx={{
          width: {
            xs: "calc(100% - 40px)",
            md: "50%",
          },
          height: "2px",
          marginTop: {
            xs: "10px",
            md: "20px",
          },
          marginBottom: {
            xs: "10px",
            md: "25px",
          },
          backgroundColor: Palette.RED,
        }}
      ></Box>
      <Typography
        style={{
          fontFamily: Font.CEHUA,
          fontSize: "3rem",
        }}
      >
        Portfolio
      </Typography>
      <Box
        style={{
          position: "absolute",
          zIndex: "-1",
          height: "100%",
          left: 0,
        }}
      >
        <img
          src={portfolio}
          alt="portfolio"
          style={{
            height: "100%",
          }}
        />
      </Box>
    </Box>
  );
};
