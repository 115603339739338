import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Dialog,
  Grid,
  Typography,
} from "@mui/material";
import { Font, Palette } from "./palette";
import { Portfolio } from "./portfolio";
import React from "react";
import { PortfolioView } from "./PortfolioView";

const PorfolioCard = ({ portfolio }) => {
  const [open, setOpen] = React.useState(false);

  const toggleOpen = () => {
    setOpen((b) => !b);
  };

  return (
    <>
      <Dialog open={open} onClose={toggleOpen} maxWidth="md">
        <Card
          style={{
            height: "80vh",
            overflow: "scroll",
          }}
        >
          <PortfolioView portfolio={portfolio} />
        </Card>
      </Dialog>
      <Card
        sx={{
          width: "100%",
          height: "100%",
          boxShadow: 3,
          // margin: {
          //   xs: "15px 15px 0 15px",
          //   xs: "18px 18px 0 18px",
          //   md: "10px",
          // },
          backgroundColor: Palette.WHITE,
        }}
      >
        <CardActionArea onClick={toggleOpen}>
          <CardMedia
            component="img"
            height="200"
            src={portfolio.pngUrls.small[0]}
          />
          <CardContent
            style={{
              border: "1px solid black",
              borderWidth: "2px 0 0 0",
              borderColor: Palette.RED,
            }}
          >
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              style={{
                fontFamily: Font.CEHUA,
              }}
            >
              {portfolio.title}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              style={{
                fontFamily: Font.RAILWAY,
                fontWeight: "bold",
              }}
            >
              {portfolio.subtitle}
            </Typography>
            {/* <Typography variant="body2" color="text.secondary" align="left" style={{
                fontFamily: Font.RAILWAY,
                fontWeight: 'light',
            }}>
            {portfolio.descriptions[0].substring(0, 150)}...
            </Typography> */}
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

export const GridPortal = () => {
  return (
    <Box
      sx={{
        backgroundColor: Palette.LIGHT_PINK,
        padding: {
          xs: "10px",
          sm: "20px",
          md: "20px",
          lg: "30px",
        },
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          maxWidth: {
            lg: "1500px",
          },
        }}
      >
        {Portfolio.map((portfolio, index) => (
          <Grid item key={index} xs={12} sm={4} lg={3}>
            <PorfolioCard portfolio={portfolio} key={index} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
